import MDRouter from "@/service/router";
import lowerdev from "@/service/lowerdev";
import { fmoney } from '@/utils/tools.js';
import { env } from "md-base-tools/env";
import { mapState } from "vuex";
import { Icon, CountDown } from 'vant';
import Error from "@@/common/Error.vue";
import { getExactProjectDaily, getCategoryTopList, setProjectDailyRead } from "@/api";
export default {
  components: {
    Icon,
    [CountDown.name]: CountDown,
    Error
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      icon: 'icon_nodaily',
      env,
      showError: false,
      showMessage: "",
      loading: false,
      month: '',
      date: '',
      early_daily_list: [],
      late_daily_list: [],
      rank_category: [],
      recommend_item: []
    };
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  async mounted() {
    await this.getInfo();
  },
  unmounted() {},
  methods: {
    onRefresh() {
      this.getInfo();
    },
    // 获取日历
    async getInfo() {
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      function getSearchParamsObject(searchParams) {
        const searchParamsObject = {};
        for (let [key, value] of searchParams) {
          searchParamsObject[key] = value;
        }
        return searchParamsObject;
      }
      const searchParamsObject = getSearchParamsObject(searchParams);
      const {
        status,
        data,
        message
      } = await getExactProjectDaily(searchParamsObject);
      if (data && data.daily_date) {
        this.month = new Date(data.daily_date).getMonth() + 1 > 9 ? new Date(data.daily_date).getMonth() + 1 : '0' + (new Date(data.daily_date).getMonth() + 1);
        this.date = new Date(data.daily_date).getDate() > 9 ? new Date(data.daily_date).getDate() : '0' + new Date(data.daily_date).getDate();
      }
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "今天日报停更一天哦～";
        return;
      } else {
        // 设置日报已读请求
        setProjectDailyRead({
          'daily_date': data.daily_date || searchParamsObject.daily_date
        });
        if (data && this.isNull(data.early_daily_list) && this.isNull(data.late_daily_list)) {
          this.showError = true;
          this.showMessage = message || "今天日报停更一天哦～";
          return;
        } else {
          this.showError = false;
          this.loading = false;
          if (data.early_daily_list) {
            this.early_daily_list = data.early_daily_list;
          }
          if (data.late_daily_list) {
            this.late_daily_list = data.late_daily_list;
          }
          if (data.rank_category) {
            this.rank_category = [];
            data.rank_category.forEach(async rankItem => {
              const {
                status,
                data
              } = await getCategoryTopList({
                pro_category_en: rankItem.pro_category_en,
                rank_type: rankItem.rank_type,
                rank_cate_id: rankItem.rank_cate_id
              });
              if (status == 0) {
                rankItem.list = data.splice(0, 3);
                this.rank_category.push(rankItem);
              }
            });
          }
          if (data.recommend_item) {
            this.recommend_item = [];
            data.recommend_item.forEach(recommendItem => {
              recommendItem.pro_id = recommendItem.item_id;
              this.recommend_item.push(recommendItem);
            });
          }
        }
      }
    },
    back() {
      // eslint-disable-next-line
      graft.app({
        functionName: "closeController",
        functionParams: "",
        callBack: () => {},
        webFun: () => {
          window.history.go(-1);
        }
      });
    },
    errorConfirm() {
      MDRouter.goProjectList();
    },
    goPro(item, index, type) {
      this.sensors.track("PositionClick", {
        sec_business_line: item.pro_class,
        //pro_class
        page_source: "摩点日报",
        item_id: item.pro_id,
        item_name: item.title,
        module: type == 'late' ? '末班车' : '上新',
        module_position: index + 1
      });
      this.goProSingle(item.pro_id);
    },
    // 排行榜进入
    goRankToPro(item, index) {
      this.sensors.track("PositionClick", {
        sec_business_line: item.pro_class || 101,
        page_source: "摩点日报",
        item_id: item.pro_id,
        item_name: item.short_title,
        module: '排行榜',
        module_position: index + 1
      });
      this.goProSingle(item.pro_id);
    },
    // 项目推荐进入
    goRecommondToPro(item, index) {
      this.sensors.track("PositionClick", {
        sec_business_line: 101,
        page_source: "摩点日报",
        item_id: item.item_id,
        item_name: item.title,
        module: '项目推荐',
        module_position: index + 1
      });
      this.goProSingle(item.item_id);
    },
    // 去项目详情
    goProSingle(pro_id) {
      MDRouter.goProInfo(pro_id);
    },
    // 去排行榜
    goRank(item) {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: item.md_link,
        webFun: () => {
          if (sessionStorage.getItem("miniProgram") === "weixin" || env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: '/pages/category_rank/category_rank?pro_category_en=' + item.pro_category_en + '&rank_cate_id=' + item.rank_cate_id + '&rank_type=' + item.rank_type
            });
          } else {
            window.location.href = item.h5_link;
          }
        }
      });
    },
    // 时间倒计时毫秒数
    countDown(str) {
      const targetTimestamp = Date.parse(str.replace(/-/g, '/'));
      const currentTimestamp = Date.now();
      const diffMilliseconds = targetTimestamp - currentTimestamp;
      return diffMilliseconds;
    },
    // 千分符
    formatAmount(amount) {
      return fmoney(amount, 2);
    },
    // 时间格式化
    formatDate(srt) {
      const date = new Date(Date.parse(srt.replace(/-/g, '/')));
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const formattedMonth = (month < 10 ? '0' : '') + month;
      const formattedDay = (day < 10 ? '0' : '') + day;
      const formattedHour = (hour < 10 ? '0' : '') + hour;
      const formattedMinute = (minute < 10 ? '0' : '') + minute;
      const formattedDate = formattedMonth + '月' + formattedDay + '日';
      const formattedTime = formattedHour + ':' + formattedMinute;
      const formattedDateTime = formattedDate + formattedTime;
      return formattedDateTime;
    },
    isNull(value) {
      return typeof value === 'object' && value === null;
    }
  }
};